.menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%; /* Ancho del menú */
    height: 100vh;
    background-color: #ECECEC; /* Color de fondo del menú */
    transition: transform 0.3s ease-in-out; /* Animación de deslizamiento */
    z-index: 1000;
    transform: translateX(-100%);
  }
  
  .menu-container.show {
    transform: translateX(0%);
  }
  
  .menu {
    list-style-type: none;
    padding: 0;
  }
  
  .menu li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .menu li:hover {
    background-color: #F0E68C; /* Color de fondo al pasar el ratón */
  }
  